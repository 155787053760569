import React from "react"
import Layout from "@components/layout"
import Paragraph from "@components/elements/paragraph"
import Heading from "@components/elements/heading"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import { SocialProofSection } from "@components/social-proof"
import { useTheme } from "styled-components"
import { HighGradient } from "@components/common/gradient"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { processImages } from "@utils/process-images"
import { ServiceLibraryAnimation } from "@components/animated/service-library"
import Button from "@components/elements/button"
import Span from "@components/elements/span"
import { ReactComponent as KlipsLogo } from "@images/comparison/klips.svg"
import Image from "@components/image"
import { GlassyDiv } from "@components/elements/special"
import { useGradient } from "@hooks/use-gradient"
import Anchor from "@components/elements/anchor"
import { Testimony } from "./klips"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const HomePageAlt = ({ data }) => {
  const { color } = useTheme()
  const images = processImages(data.images.edges)
  const { gradient: imageGradient } = useGradient(true)

  return (
    <Layout
      fullWidth
      marginless
      login
      seo={{
        title: "Make Data Everybody’s Business",
        description: `Klipfolio is a powerful dashboard and reporting analytics platform that helps businesses of all sizes make smarter and faster decisions.`,
        tracking: ["noindex", "nofollow", "noarchive"],
      }}
    >
      <Div
        width="100%"
        margin="8rem auto"
        marginMd="6rem auto"
        marginSm="6rem auto 4rem"
      >
        <Div position="relative" overflow="clip visible">
          <HighGradient
            style={{ minWidth: "2500px" }}
            top="48rem"
            src={imageGradient.cdn}
            loading="lazy"
            hideOnMd
          />
          <Flex
            margin="0 auto"
            alignItems="center"
            textContainer
            center
            position="relative"
          >
            <KlipsLogo height="60" />
            <Heading
              margin="1.5rem auto 1rem"
              fontSize="3.6rem"
              color={color.blue600}
            >
              All your data works
              <br />
              <Span color={color.blue400}>beautifully in Klipfolio</Span>
            </Heading>
            <Paragraph
              margin="0 auto 2rem"
              fontWeight={600}
              lineHeight={1.5}
              fontSize="1.5rem"
              color={color.blue600}
            >
              Get data into everyone&apos;s hands for better decision making.
              Connect to hundreds of services and APIs directly and build highly
              customizable dashboards and reports for your team and clients.
            </Paragraph>

            <Flex
              width="max-content"
              margin="0 auto 1rem"
              zIndex={10}
              position="relative"
              flexFlow="row"
              alignItems="center"
              justifyContent="center"
              alignSelf="center"
            >
              <Button.Klips notice={false}>Get Started Now</Button.Klips>

              <Anchor
                href="/klips"
                color={color.blue600}
                hover={color.blue500}
                margin="0 0 0 1rem"
              >
                Learn more
              </Anchor>
            </Flex>
          </Flex>

          <Div margin="2rem auto 2rem" position="relative" container>
            <GlassyDiv padding="1rem">
              <Div position="relative" style={{ marginBottom: "-4.4%" }}>
                <Image
                  file={images["6acae780-37eb-4243-80c9-01566e47e7ec"]}
                  style={{ clipPath: "inset(0 0 8% 0 round 10px)" }}
                />
              </Div>
            </GlassyDiv>
            <Div
              width="1290px"
              hideOnMd
              left={"-5%"}
              position="absolute"
              top={0}
            >
              <Image file={images["b9e8eaae-d694-4bdf-835d-86daf10be79f"]} />
            </Div>
          </Div>
          <Flex
            flexFlow="row"
            flexFlowMd="column"
            gap="2rem"
            margin="1rem auto 8rem"
            position="relative"
            container
          >
            <Testimony
              name="Carolyn S."
              role="People Insights Manager at formstack.com"
              image={images["21dba570-e178-4e0b-ae60-7fb373b554e8"]}
              testimony={`I like how much you can customize the look and feel of your dashboard. Klipfolio connects all of our disparate systems within our HR department. Seeing survey, HRIS, and ATS data in one
            dashboard is really cool.`}
            />
            <Testimony
              name="Andrew W."
              role="Director at IT MOOTI"
              image={images["05d55cc6-3011-4deb-a6fc-fa3da07ab2f3"]}
              testimony={`I build dashboards for clients across many industries and it is by far the best tool I've come across for building KPI reports that are used daily and motivate teams to make progress on business goals.`}
            />
          </Flex>

          <SocialProofSection />
        </Div>
      </Div>
      <Div
        container
        position="relative"
        overflow="hidden"
        margin="0 auto 8rem"
        marginMd="0 auto 6rem"
        marginSm="0 auto 4rem"
        maxWidth="100%"
      >
        <Heading
          style={{ fontSize: "clamp(2rem, 3vw + 1rem, 4rem)" }}
          margin="0 0 3rem"
          marginSm="0 0 2rem"
          as="h2"
          center
          color={color.blue600}
        >
          100+ Technology Partners
        </Heading>
        <ServiceLibraryAnimation />
      </Div>
      <KlipsEOPCta />
    </Layout>
  )
}

HomePageAlt.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePageAlt

export const pageQuery = graphql`
  query HomePageQuery {
    gradient: directusFiles(
      directus_id: { eq: "56ded7c2-7a1c-4c29-9a69-5ce23a09f1af" }
    ) {
      id
      cdn
      title
    }
    icons: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b017da8b-6c31-47ff-a77a-9f75cf6fb353"
            "3efdd553-4448-4e9e-9e5f-dabf2647b45c"
            "3aa397c0-c54c-4c24-9d6a-fa2e7641dfe9"
            "9e4c6e11-0e74-45b6-8557-3f3291b6ec1f"
            "944f1830-d5f3-42bd-9d97-247790e18cef"
            "cf9cfc3a-a202-416c-a1bf-d7aa15484c0f"
            "40dc1991-0dde-461c-b6f6-0fe65f99779e"
            "e6486f9a-8339-451c-bea4-20d70118ed2d"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "6acae780-37eb-4243-80c9-01566e47e7ec"
            "b9e8eaae-d694-4bdf-835d-86daf10be79f"
            "21dba570-e178-4e0b-ae60-7fb373b554e8"
            "05d55cc6-3011-4deb-a6fc-fa3da07ab2f3"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
  }
`
